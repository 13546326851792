import type { FunctionComponent } from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import { InstagramIcon, LinkedInIcon, TwitterIcon } from '../icons'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Footer.module.sass'
import { Link } from './Link'

export type FooterProps = {
	footer: FooterResult
}

export const Footer: FunctionComponent<FooterProps> = ({ footer }) => {
	const desiredOrder = ['Products', 'Community', 'Why Ceros?', 'About']

	const sortedLinkLists = desiredOrder
		.map((title) => new Map(footer.linkLists.map((item) => [item.title, item])).get(title))
		.filter((item) => item)

	return (
		<footer className={styles.wrapper}>
			<Container size="normal">
				<div className={styles.footerLinksContainer}>
					<div className={styles.footerLeftSide}>
						{footer.logo && (
							<Link href="/" className={styles.logoContainer}>
								<ContemberImage image={{ ...footer.logo, alt: footer.logo.alt || 'logo' }} />
							</Link>
						)}
						<div className={styles.socialLinks}>
							<a
								href="https://www.instagram.com/cerosdotcom/?hl=en"
								target="_blank"
								rel="noopener noreferrer">
								<InstagramIcon />
							</a>
							<a
								href="https://www.linkedin.com/company/cerosdotcom"
								target="_blank"
								rel="noopener noreferrer">
								<LinkedInIcon />
							</a>
							<a href="https://twitter.com/Cerosdotcom" target="_blank" rel="noopener noreferrer">
								<TwitterIcon />
							</a>
						</div>
					</div>
					<div className={styles.footerLinks}>
						{sortedLinkLists?.map((item: any) => (
							<div key={item.id} className={styles.footerLinksWrapper}>
								<div>{item?.icon?.icon && <ContemberImage image={item?.icon?.icon as any} />}</div>
								<div className={styles.topLinks}>
									<p className={styles.title}>{item.title}</p>
									{item.items.map(
										(item: any) =>
											item.link && <Link key={item.id} link={item.link} className={styles.link} />
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={styles.bottomLinksWrapper}>
					<p className={styles.copyright}>{footer.copyright}</p>
					<div className={styles.bottomLinks}>
						{footer.bottomLinks?.items.map(
							(item) =>
								item.link && <Link key={item.id} link={item.link} className={styles.bottomLink} />
						)}
					</div>
				</div>
			</Container>
		</footer>
	)
}
